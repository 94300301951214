/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import {
    faArrowDown,
    faArrowRotateLeft,
    faAt,
    faBackward,
    faBars,
    faBell,
    faCalculator,
    faCalendarDays,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faCircleExclamation,
    faCircleInfo,
    faCity,
    faClipboardList,
    faClock,
    faCloudArrowUp,
    faCopy,
    faDatabase,
    faDownload,
    faExchangeAlt,
    faExclamation,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileCirclePlus,
    faFileCircleXmark,
    faFileContract,
    faFilePdf,
    faFileSignature,
    faGear,
    faInfo,
    faKey,
    faList,
    faMagnifyingGlass,
    faMinus,
    faMoon,
    faPaperclip,
    faPencilAlt,
    faPlus,
    faQrcode,
    faSatellite,
    faShieldAlt,
    faSignOutAlt,
    faSquare,
    faStar,
    faSun,
    faTrash,
    faUpload,
    faUpRightFromSquare,
    faUser,
    faUserGear,
    faUsers,
    faUserShield,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';

import {
    faBuilding as farBuilding,
    faCalendarCheck as farCalendarCheck,
    faEnvelope as farEnvelope,
    faFile as farFile,
    faFilePdf as farFilePdf,
    faLightbulb as farLightbulb,
} from '@fortawesome/free-regular-svg-icons';

/* add icons to the library */
library.add(
    faArrowDown,
    faArrowRotateLeft,
    faAt,
    faBackward,
    faBars,
    faBell,
    faCalculator,
    faCalendarDays,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faCircleExclamation,
    faCircleInfo,
    faCity,
    faClipboardList,
    faClock,
    faCloudArrowUp,
    faCopy,
    faDatabase,
    faDownload,
    faEye,
    faEyeSlash,
    faExchangeAlt,
    faExclamation,
    faFile,
    faFileAlt,
    faFileCirclePlus,
    faFileCircleXmark,
    faFileContract,
    faFilePdf,
    faFileSignature,
    faGear,
    faInfo,
    faKey,
    faList,
    faMagnifyingGlass,
    faMinus,
    faMoon,
    faPaperclip,
    faPencilAlt,
    faPlus,
    faQrcode,
    faSatellite,
    faShieldAlt,
    faSignOutAlt,
    faSquare,
    faStar,
    faSun,
    faTrash,
    faUpload,
    faUpRightFromSquare,
    faUser,
    faUserGear,
    faUsers,
    faUserShield,
    faXmark,

    farBuilding,
    farCalendarCheck,
    farFile,
    farFilePdf,
    farEnvelope,
    farLightbulb,
);
